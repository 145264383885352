import React from "react"

import { Layout } from "../components/layout"
import { SearchPortFoliosHeader } from "../components/SearchPortFoliosHeader"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Search Portfolios",
    url: "/search-porfolios",
  },
]

const SearchPortfolios: React.FC = ({ data }) => {
  return (
    <Layout title={"Graduate Fashion Week - Search Portfolio"}>
      <SearchPortFoliosHeader
        breadcrumb={breadcrumbData}
        title={"EXPLORE OUR 2021 GRADUATES"}
        intro={
          "Head back this May to scout talent from over 1500+ graduate portfolio profiles from universities around the world. Our hub allows you, the industry, to view the very best curated graduate work, with addtional search options for over 40 specialisms making it easier for you to refine and find the best hire for you."
        }
        catUrl={"/"}
        tags={[]}
      />
    </Layout>
  )
}

export default SearchPortfolios
