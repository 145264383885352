import React, { FunctionComponent } from "react"
import styled from "styled-components"
import Breadcrumb from "./Breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "./Buttons/Buttons"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
interface PartnerHeaderI {
  title: string
  intro: string
  catUrl: string
  tags: string[]

  breadcrumb: IBreadcrumb
}
interface Item {
  title: string
  url: string
}
type IBreadcrumb = Array<Item>
export const SearchPortFoliosHeader: FunctionComponent<PartnerHeaderI> = ({
  title,
  intro,
  catUrl,
  tags,
  breadcrumb,
}) => {
  const headerimage = useStaticQuery(graphql`
    {
      placeholderImage: file(name: { eq: "portfolios_header" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <>
      <BreadCrumbContainer>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumb} />
        </Container>
      </BreadCrumbContainer>
      <ImgContainer>
        <GatsbyImage
          image={headerimage.placeholderImage.childImageSharp.gatsbyImageData}
          className={"img"}
          loading={"eager"}
          alt={""}
        />
      </ImgContainer>
      <Container maxWidth={"lg"}>
        <Box>
          <h1>{title}</h1>
          <PartnerIntro>{intro}</PartnerIntro>
          <Button to={catUrl} size={2} customwidth={"40%"}>
            Go To Homepage
          </Button>

          <TagContainer>
            {tags.map(item => {
              return <Tag key={uuidv4()}>{item}</Tag>
            })}
          </TagContainer>
        </Box>
      </Container>
    </>
  )
}

const BreadCrumbContainer = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: ${props => props.theme.tints.lighterGrey};
`

const ImgContainer = styled.div`
  width: 100%;

  height: 720px;
  overflow: hidden;
  &img {
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      object-fit: fill;
    }
  }
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -350px;
  margin-bottom: 70px;
  max-width: 660px;
  max-height: 560px;
  padding: 63px;
  background-color: black;
  color: white;
  > h1 {
    color: white;
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -550px;
    margin-bottom: 0px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-bottom: 0px;
    padding: 40px 20px;
  }
`
const PartnerIntro = styled.p`
  color: white;
  margin-bottom: 34px;
`

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 34px;
`
const Tag = styled.b`
  text-align: center;
  text-transform: uppercase;
  border: 1px solid white;
  font-size: 0.8rem;
  margin: 5px;
  height: 20px;
  width: auto;
  padding: 1px 8px 0;
  border-radius: 10px;
`
